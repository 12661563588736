<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="票根名" prop="name" >
        <a-input v-model="form.name" placeholder="请输入票根名" />
      </a-form-model-item>
      <a-form-model-item label="票根类型" prop="type" >
        <a-select style="width: 100%" v-model="form.type" placeholder="请选择票根类型">
          <a-select-option v-for="(item, index) in this.customDict.TicketStubTypeEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="剧本id 剧本票根时使用" prop="scriptId" >
        <a-button type="dashed" @click="showBindScript" danger v-if="form.scriptName">
          <span>{{ form.scriptName }}</span>
        </a-button>
        <a-button type="dashed" @click="showBindScript" danger v-if="!form.scriptName">
          <span style="color: dodgerblue"> 绑定剧本</span>
        </a-button>
      </a-form-model-item>
      <a-form-model-item label="制作材料类型" prop="materialType" >
        <a-select style="width: 100%" v-model="form.materialType" placeholder="请选择制作材料类型">
          <a-select-option v-for="(item, index) in this.customDict.TicketStubMaterialTypeEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="票根等级" prop="level" >
        <a-select style="width: 100%" v-model="form.level" placeholder="请选择票根等级">
          <a-select-option v-for="(item, index) in this.customDict.TicketStubLevelEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="可交易次数 默认3次" prop="transNumber" >
        <a-input v-model="form.transNumber" placeholder="请输入可交易次数 默认3次" />
      </a-form-model-item>
      <a-form-model-item label="票根正面图片" prop="stubFrontImg" >
        <oss-multi-upload
          v-model="form.stubFrontImg"
          type="img"
          bucket="ticket"
          :maxCount="1"
          :isMultiple="false"
        ></oss-multi-upload>
      </a-form-model-item>
      <a-form-model-item label="票根背面图片" prop="stubBackImg" >
        <oss-multi-upload
          v-model="form.stubBackImg"
          type="img"
          bucket="ticket"
          :maxCount="1"
          :isMultiple="false"
        ></oss-multi-upload>
      </a-form-model-item>
      <a-form-model-item label="票根正面灰色图片" prop="stubFrontGreyImg" >
        <oss-multi-upload
          v-model="form.stubFrontGreyImg"
          type="img"
          bucket="ticket"
          :maxCount="1"
          :isMultiple="false"
        ></oss-multi-upload>
      </a-form-model-item>
      <a-form-model-item label="票根背面图片" prop="stubBackGreyImg" >
        <oss-multi-upload
          v-model="form.stubBackGreyImg"
          type="img"
          bucket="ticket"
          :maxCount="1"
          :isMultiple="false"
        ></oss-multi-upload>
      </a-form-model-item>
      <a-form-model-item label="是否可以自定义图片" prop="isDiyImg" >
        <a-switch v-model="form.isDiyImg" />{{ form.isDiyImg ? '是' : '否' }}
      </a-form-model-item>
      <a-form-model-item label="是否可以自定义内容" prop="isDiyContent" >
        <a-switch v-model="form.isDiyContent" />{{ form.isDiyContent ? '是' : '否' }}
      </a-form-model-item>
      <a-form-model-item label="状态" prop="status" >
        <a-select style="width: 100%" v-model="form.status" placeholder="请选择状态">
          <a-select-option v-for="(item, index) in this.customDict.TicketStubStatusEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
    <index-script-modal
      ref="IndexScriptModal"
      @select="indexScriptModalSelect"
      @selectName="indexScriptModalName"
    ></index-script-modal>
  </a-drawer>
</template>

<script>
import { addTicketStub, getTicketStub, updateTicketStub } from '@/api/ticket/ticketStub'
import { mapGetters } from 'vuex'
import OssMultiUpload from '@/views/script/script/modules/OssMultiUpload.vue'
import IndexScriptModal from '@/components/script/IndexScriptModal'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    IndexScriptModal,
    OssMultiUpload
  },
  data () {
    return {
      loading: false,
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        name: null,
        type: null,
        scriptId: null,
        scriptName: null,
        level: null,
        materialType: null,
        transNumber: null,
        stubFrontImg: null,
        stubBackImg: null,
        stubFrontGreyImg: null,
        stubBackGreyImg: null,
        isDiyImg: false,
        isDiyContent: false,
        status: 1,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [
          { required: true, message: '票根名不能为空', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '票根类型 1.剧本票根 2.节日票根 3.周年票根 4.其他不能为空', trigger: 'change' }
        ],
        transNumber: [
          { required: true, message: '可交易次数 默认3次不能为空', trigger: 'blur' }
        ],
        stubFrontImg: [
          { required: true, message: '票根正面图片不能为空', trigger: 'blur' }
        ],
        stubBackImg: [
          { required: true, message: '票根背面图片不能为空', trigger: 'blur' }
        ],
        stubFrontGreyImg: [
          { required: true, message: '票根正面灰色图片不能为空', trigger: 'blur' }
        ],
        stubBackGreyImg: [
          { required: true, message: '票根背面灰色图片不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '状态 0.禁用 1.启用不能为空', trigger: 'blur' }
        ]
        // isDeleted: [
        //   { required: true, message: '逻辑删除标记，1：删除，0：未删除，默认0不能为空', trigger: 'blur' }
        // ],
        // version: [
        //   { required: true, message: '乐观锁不能为空', trigger: 'blur' }
        // ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    indexScriptModalSelect(ids) {
      this.form.scriptId = ids
      console.log('获取到的ids', ids)
    },
    indexScriptModalName(name) {
      this.form.scriptName = name
      console.log('获取到的剧本名', name)
    },
    showBindScript: function() {
      this.$refs.IndexScriptModal.open()
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        name: null,
        type: null,
        scriptId: null,
        scriptName: null,
        level: null,
        materialType: null,
        transNumber: null,
        stubFrontImg: null,
        stubBackImg: null,
        stubFrontGreyImg: null,
        stubBackGreyImg: null,
        isDiyImg: false,
        isDiyContent: false,
        status: 1,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getTicketStub(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateTicketStub(this.form).then(() => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addTicketStub(this.form).then(() => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
