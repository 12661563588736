import request from '@/utils/request'

// 查询票根列表
export function listTicketStub(query) {
  return request({
    url: '/ticket/stub/list',
    method: 'get',
    params: query
  })
}

// 查询票根详细
export function getTicketStub(id) {
  return request({
    url: '/ticket/stub/' + id,
    method: 'get'
  })
}

// 新增票根
export function addTicketStub(data) {
  return request({
    url: '/ticket/stub',
    method: 'post',
    data: data
  })
}

// 修改票根
export function updateTicketStub(data) {
  return request({
    url: '/ticket/stub',
    method: 'put',
    data: data
  })
}

// 删除票根
export function delTicketStub(id) {
  return request({
    url: '/ticket/stub/' + id,
    method: 'delete'
  })
}
